<template>
    <div class="animated fadeIn">
        <CRow>
            <CCol lg="7">
                <CCard>
                    <CCardHeader><font-awesome-icon icon="share-from-square" /> New Template Prompts Shared
                    </CCardHeader>
                    <CCardBody>

                        <CRow>
                            <CCol lg="12" md="12" sm="12" style="padding-top: 2%;">
                                <label>Select Prompt</label>
                                <CInput readonly v-model="selected_prompt.name" :class="inputStyle">
                                    <template #append>
                                        <CButton color="primary" @click="prompt_modal = true, getPrompts()">

                                            <font-awesome-icon icon="search" />
                                        </CButton>
                                    </template>
                                </CInput>
                            </CCol>
                        </CRow>

                        <CRow>
                            <CCol lg="12" style="padding-top: 1%;">
                                <label>Document Template/s</label>
                                <div style="display: flex;" class="search-container">
                                    <span style="font-size: 14px; padding-top: 5px; padding-right: 5px;">Search:
                                    </span>
                                    <input type="text" id="search"
                                        :class="$store.getters.getDarkMode ? 'dark-theme search-box' : 'search-box'"
                                        placeholder="Enter text here." v-model="searchTerm">
                                </div>
                                <CDataTable :items="filteredItems" :fields="dt_field" :items-per-page="5" border class="templates-table"
                                    @row-clicked="rowClicked" sorter hover pagination :loading="this.table_loading">
                                    <template slot="no-items-view" v-if="this.table_loading">
                                        <center>
                                            <CSpinner style="width:4rem;height:4rem;" color="info" />
                                        </center>
                                    </template>

                                    <template #checkbox-header>
                                        <center>
                                            <p-check class="p-icon p-bigger" color="success" v-model="isCheckedAll"
                                                @change="selectChange($event)">
                                                <font-awesome-icon class="icon" icon="check" />
                                            </p-check>
                                        </center>
                                    </template>

                                    <template #checkbox="{ item }">
                                        <td class="checkbox-column">
                                            <center>
                                                <p-check class="p-icon p-bigger" color="success" :value="item"
                                                    v-model="selected_templates">
                                                    <font-awesome-icon class="icon" icon="check" />
                                                </p-check>
                                                &nbsp;
                                            </center>
                                        </td>
                                    </template>

                                </CDataTable>
                            </CCol>
                        </CRow>

                        <CRow>
                            <CCol col="12" class="text-right">
                                <CButton :disabled="isValid" type="submit" shape="pill" color="primary"
                                    @click="createNewDocumentShared()">
                                    <font-awesome-icon icon="save" /> Save
                                </CButton>
                            </CCol>
                        </CRow>

                    </CCardBody>
                </CCard>
            </CCol>

            <CCol lg="5">
                <CCard v-if="selected_prompt.name != ''">
                    <CCardHeader><font-awesome-icon icon="share-from-square" /> Document Template/s linked to <b>{{
                        selected_prompt.name }}</b> prompt
                    </CCardHeader>

                    <template>

                        <CCardBody>
                            <CRow>
                                <CCol lg="12">
                                    <CDataTable :items="sorted_dt_list" :fields="dt_list_field"
                                        :table-filter="{ placeholder: 'Your text here.', label: 'Search:' }"
                                        :items-per-page="10" items-per-page-select border sorter pagination
                                        :loading="list_table_loading" size="sm">
                                        <template slot="no-items-view" v-if="this.list_table_loading">
                                            <center>
                                                <CSpinner style="width:4rem;height:4rem;" color="info" />
                                            </center>
                                        </template>
                                        <template #name="{ item }">
                                            <td :title="item.name">
                                                {{ item.name.length > 30 ? item.name.substring(0, 30).trim() + '...' :
                                                    item.name }}
                                            </td>
                                        </template>
                                    </CDataTable>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </template>
                </CCard>
            </CCol>
        </CRow>
        <CModal :show.sync="prompt_modal" color="primary" size="lg" :closeOnBackdrop="false">
            <template #header>
                <h5> <font-awesome-icon icon="user" /> Prompt list </h5>
                <CButton class="pull-right" color="light" shape="pill" size="sm" @click="prompt_modal = false">
                    <font-awesome-icon icon="times" />
                </CButton>
            </template>
            <template #body-wrapper>
                <CCardBody>
                    <CDataTable :items="sorted_prompts" :fields="prompt_fields" class="prompts-table-create"
                        :table-filter="{ placeholder: 'Your text here.', label: 'Search:' }" :items-per-page="10"
                        items-per-page-select border sorter pagination hover @row-clicked="selectedPrompt"
                        :loading="table_loading">
                    </CDataTable>
                </CCardBody>
            </template>
            <template #footer>
                <hr hide>
            </template>
        </CModal>
    </div>
</template>
<style>
.prompts-table-create tbody tr, .templates-table tbody tr{
  cursor: pointer;
}

.templates-table tbody tr td.checkbox-column{
  cursor: default
}

</style>

<script>
export default {
    name: 'DocumentTemplateSharedCreate',
    data() {
        return {
            selected_category: null,
            category_list: [],
            category_required: false,
            collapse_shared: false,
            table_loading: true,
            isCheckedAll: false,
            document_template_list: [],
            selected_templates: [],
            selected_user: {
                name: '',
                head_id: ''
            },
            selected_prompt: {
                name: '',
            },

            document_templates: [],
            document_templates_fields: [
                { key: 'name', label: 'Name' },
                { key: 'category_name', label: 'Category Name' },
                // {key: 'description', label: 'Description'},
            ],
            prompts: [],
            prompt_modal: false,
            templates_field: [],
            dt_shared: [],
            list_table_loading: false,
            dt_list: [],
            searchTerm: '',
            filtered_data: [],
        }
    },
    created() {
        this.getCategoryList();

    },
    computed: {
        prompt_fields() {
            let custom_fields = [
                { key: 'name', label: 'Name' },
                { key: 'description', label: 'Description' },
            ];
            return custom_fields;
        },

        isValid() {
            return this.selected_templates.length > 0 ? false : true;
        },

        sorted_document_template_list() {
            return this.document_template_list.sort((a, b) => a.name.localeCompare(b.name));
        },
        sorted_prompts() {
            return this.prompts.sort((a, b) => a.name.localeCompare(b.name));
        },
        sorted_document_templates_shared() {
            return this.document_templates.sort((a, b) => a.name.localeCompare(b.name));
        },
        // sorted_dt_shared() {
        //     return this.dt_list
        //         .filter(item => item.deleted_at === null)
        //         .sort((a, b) => a.name.localeCompare(b.name));
        // },
        filteredItems() {
            let filteredData =  this.dt_list
            .filter(item => item.deleted_at === null)
            .filter(item =>
                Object.values(item).some(value =>
                value != null && value.toString().toLowerCase().includes(this.searchTerm.toLowerCase())
                )
            ).sort((a, b) => a.name.localeCompare(b.name));

            this.filtered_data = filteredData;
            return filteredData;
        },
        sorted_dt_list() {
            return this.dt_shared
            .filter(item => item.deleted_at === null)
            .sort((a, b) => a.name.localeCompare(b.name));
        },

        inputStyle() {
            return {
                'selected-user-required': this.selected_prompt.name == '' && !this.$store.getters.getDarkMode,
                'selected-user-required-dark': this.selected_prompt.name == '' && this.$store.getters.getDarkMode,
                'selected-user-filled': this.selected_prompt.name != '' && !this.$store.getters.getDarkMode,
                'selected-user-filled-dark': this.selected_prompt.name != '' && this.$store.getters.getDarkMode,
            }
        },
        dt_field: function () {
            let custom_fields = [
                {
                    key: "checkbox", label: '', _classes: 'th-fixed-width'
                },
                { key: 'name', label: 'Template Name' },
                { key: 'category_name', label: 'Category Name' },
            ]
            return custom_fields;
        },
        dt_list_field: function () {
            let custom_fields = [
                { key: 'name', label: 'Template Name' },
                { key: 'category_name', label: 'Category Name' },
            ]
            return custom_fields;
        }
    },
    methods: {

        promptSelected: function (object) {
            this.selected_prompt.name = object.name

            this.getDocumentTemplateShared(this.selected_prompt.id);
            if (this.selected_prompt != null) {
                this.getDocumentTemplateSharedList(this.selected_prompt.id);
            }
        },

        getCategoryList: function () {
            this.$Progress.start();
            axios.get('/drs/document-template-category/list', { validateStatus: () => true })
                .then(response => {
                    if (response.status == 200) {

                        for (let i = 0; i < response.data.data.length; i++) {
                            let option = {
                                id: response.data.data[i].id,
                                name: response.data.data[i].category,
                            };

                            this.category_list.push(option);
                        }
                        this.table_loading = false;
                        this.$Progress.finish()
                    }
                })
        },

        getDocumentTemplateShared: function (prompt_id) {
            this.$Progress.start()
            this.table_loading = true;
            if (this.document_templates.length > 0) {
                this.document_templates = [];
            }
            axios.post('/drs/template-prompt-shared/dt-list-with-param/' + prompt_id, { validateStatus: () => true })
                .then(response => {
                    if (response.status == 200) {
                        this.dt_list = response.data.data;
                        this.table_loading = false
                        this.$Progress.finish()
                    }
                })
        },
        getDocumentTemplateSharedList: function (prompt_id) {
            let encoded_prompt_id = this.paramEncoder(prompt_id);
            let _data = {
                prompt_id: encoded_prompt_id
            };
            this.$Progress.start()
            this.list_table_loading = true;
            if (this.document_templates.length > 0) {
                this.document_templates = [];
            }
            axios.post('/drs/template-prompt-shared/dt-list', _data, { validateStatus: () => true })
                .then(response => {
                    if (response.status == 200) {
                        this.dt_shared = response.data.data;
                        this.list_table_loading = false
                        this.$Progress.finish()
                    }
                })
        },
        getDocumentTemplates: function (category_id) {
            const requestData = {
                dt_category_id: category_id,
                user_id: this.selected_user.head_id
            };
            this.$Progress.start();
            axios.post('/drs/document-template-shared/dtc-list-with-parameter', requestData, { validateStatus: () => true })
                .then(response => {
                    if (this.document_template_list.length > 0) {
                        this.document_template_list = [];
                        this.selected_templates = [];

                    }
                    if (response.status == 200) {
                        this.document_template_list = [];
                        this.selected_templates = [];

                        this.isCheckedAll = false;
                        for (let i = 0; i < response.data.data.length; i++) {
                            const category = response.data.data[i].document_template_category;
                            let data = {
                                id: response.data.data[i].id,
                                name: response.data.data[i].name,
                                category_name: category.category_name,
                            };

                            let item = this.document_templates.findIndex((templates) => templates.document_template_id == data.id);
                            if (item == -1) {
                                this.document_template_list.push(data);
                            }
                        }
                        this.table_loading = false;
                        this.$Progress.finish()
                    }

                }
                );
        },

        createNewDocumentShared: function () {
            let _data = {
                document_template_id: [],
                prompt_id: this.selected_prompt.id
            };

            if (this.selected_templates.length > 0) {
                for (let i = 0; i < this.selected_templates.length; i++) {
                    let dt_id = this.paramEncoder(this.selected_templates[i].id);
                    _data.document_template_id.push(dt_id);
                }
            }

            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: "You are trying to share this document template(s).",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => {
                    this.$Progress.start()
                    return axios.post('/drs/template-prompt-shared/replace', _data, { validateStatus: () => true }).then(response => {
                        if (response.status == 200) {
                            this.$swal({
                                toast: true,
                                position: 'top-right',
                                showConfirmButton: false,
                                timer: 3000,
                                icon: 'success',
                                title: `Document Template(s) shared successfully!`,
                                showConfirmButton: false,
                                timerProgressBar: true,
                            })
                            this.clearData();
                            this.searchTerm = '';
                            this.$Progress.finish()

                        }
                    })
                }
            }).then((result) => {
                if (!result.value) {
                    this.$swal('Cancelled!', '', 'error');
                }
            });
            return;
        },

        clearData: function () {
            this.selected_prompt = { name: '' };
            this.dt_list = [];
            this.isCheckedAll = false;
            this.prompts = [];
        },



        rowClicked: function (item, index, column, e) {
            if (column != 'checkbox') {
                let i = this.selected_templates.findIndex((templates) => templates.id === item.id);
                if (i !== -1) {
                    if (column != 'checkbox') {

                        this.selected_templates.splice(i, 1);
                    } else {
                        if (this.selected_templates.length == 1) {
                            this.isCheckedAll = false;
                            this.selected_templates = [];

                        }
                    }
                    if (this.selected_templates.length < 1) {
                        this.isCheckedAll = false;

                    }
                } else {
                    this.selected_templates.push(item);
                    console.log(this.selected_templates);
                    this.collapse = true;
                }
            }

        },

        selectChange: function() {

            if (!this.isCheckedAll) {
                this.selected_templates = [];
                this.collapse = false;
            } else {
                if (this.searchTerm != '') {
                    if (this.selected_templates.length > 0) {
                        for (let i = 0; i < this.filtered_data.length; i++) {
                            this.selected_templates.push(this.filtered_data[i]);
                        }

                    } else {
                        this.selected_templates = this.filtered_data.map(item => item);
                    }
                } else {
                    this.selected_templates = this.dt_list.map(item => item);
                }
                if (this.dt_list.length > 0) {
                    this.collapse = true;
                }
            }
        },
        selectedPrompt: function (item, index, column, event) {
            this.selected_prompt.name = item.name;
            this.selected_prompt.id = item.id;
            this.prompt_modal = false
            this.promptSelected(item);
        },
        getPrompts: function () {
            this.$Progress.start()
            axios.get('drs/template-prompt-shared/list', { validateStatus: () => true })
                .then(response => {
                    this.prompts = response.data.data;
                    this.table_loading = false;
                    this.$Progress.finish()
                });
        },
        filteredItems() {
            let filteredData = this.document_template_shared
                .filter(item => item.deleted_at === null)
                .filter(item =>
                    Object.values(item).some(value =>
                        value != null && value.toString().toLowerCase().includes(this.searchTerm.toLowerCase())
                    )
                ).sort((a, b) => a.name.localeCompare(b.name));

            this.filtered_data = filteredData;
            return filteredData;
        },
    },
    watch: {
        selected_category: function (value) {
            if (value) {
                this.getDocumentTemplates(value.id);
            }
            if (value == null) {
                this.selected_templates = [];
                this.document_template_list = [];
            }
            this.category_required = value ? true : false
        },
    }
}
</script>